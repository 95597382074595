// color variants
@import 'themes-vars.module.scss';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

.project-info {
  .slick-current {
    opacity: 1 !important;
    .MuiTypography-root {
      color: $primaryMain;
    }
  }
}
